.watch-icon {
  background: #f1f1f1;
  border-radius: 50%;
}
.bg-light {
  background: #f1f1f1;
}
.bg-dark {
  background: #2e2f31;
}
.dashboard-heading-light {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 172.7%;

  color: #414141;
}
.dashboard-heading-dark {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 172.7%;

  color: #e2e2e2;
}
.header-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;

  color: #010018;
}

.search-bar {
  border: none;
  background: #e2e2e2;
  border-radius: 3px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  color: #414141;
}
.statistics-icons {
  background-image: url("../../assets//images/icons-background.png");
}
.statistics-box {
  background: #ffffff;

  border: 1px solid #e2e2e2;
  border-radius: 5px;
}
.statistics-heading-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: #414141;
}
.statistics-numbers-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;

  color: #010018;
}
.statistics-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  background-color: #ffffff;

  border: 1px solid #e2e2e2;
  border-radius: 5px;
}
.statistics-card-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  color: #010018;
}
.statistics-card-text2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 9px;
  line-height: 14px;

  color: #000000;
}
.statistics-card-btn {
  background: #4182eb;
  border-color: transparent;
  border-radius: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
}
.transfer-watches-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  cursor: pointer;

  color: #4182eb;
}
@media only screen and (max-width: 600px) {
  .header-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;

    color: #010018;
  }

  .search-bar {
    border: none;
    background: #e2e2e2;
    border-radius: 3px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 7px;
    line-height: 12px;

    color: #414141;
  }
}
@media only screen and (max-width: 370px) {
  .header-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;

    color: #010018;
  }
}

.nav-link {
  padding: 8px;
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;

  color: #414141 !important;
  border-radius: 200px !important;
}
.nav-link.active {
  padding: 8px;
  font-family: "DM Sans" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;

  color: #232241 !important;

  background: #ffffff !important;
  border-radius: 200px !important;
}
.main-layout {
  min-height: calc(100vh - 58px);
}

.col-2-5 {
  width: 22%;
}
@media only screen and (max-width: 828px) {
  .col-2-5 {
    width: 100%;
  }
}
