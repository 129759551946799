.wallet-btn {
  border: none;
  background: #4182eb;
  border-radius: 3px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #ffffff;
}
.card-container {
  background: #e2e2e2;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
}
.main-heading-nft {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 27px;

  color: #010018;
}

.nfts-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 135%;

  color: #414141;
}

.nfts-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 172.7%;

  color: #010018;
}
.nft-detail-layout {
  background-color: #e2e2e2;
  border-radius: 10px !important;
}
