.authenticate-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;

  /* Primary Text */

  color: #010018;
}
.authenticate-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #010018;
}
.authenticate-text2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  /* Secondary Text */

  color: #414141;
}
.sign-in-btn {
  background: #4182eb;
  border-radius: 3px;
  border: none;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;
}
.input-code-box {
  text-align: center;
  width: 7%;
  height: 40px;
  margin-right: 4px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 172.7%;

  color: #414141;
}
@media only screen and (max-width: 450px) {
  .input-code-box {
    text-align: center;
    width: 14%;
    height: 40px;
    margin-right: 4px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 172.7%;

    color: #414141;
  }
}
