.transfer-watch-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;

  color: #414141;
}
.transfer-watch-nmb {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  text-align: center;

  /* White */

  color: #ffffff;
}
