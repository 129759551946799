.client-card {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
}
.details-container-heading-light {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  color: #414141;
}
.details-container-heading-dark {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  color: #ffffff;
}
.main-layout2 {
  min-height: calc(100vh - 0px);
}
.client-detail-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 135%;

  color: #414141;
}

.client-detail-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 172.7%;

  color: #010018;
}
