.forget-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;

  /* Primary Text */

  color: #010018;
}
.forget-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #010018;
}
.forget-text2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  /* Secondary Text */

  color: #414141;
}
.sign-in-btn {
  background: #4182eb;
  border-radius: 3px;
  border: none;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;
}
.input-code-box1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e2e2e2;
  border-radius: 5px;
  border: none;
  width: 8%;
  height: 40px;
  margin-right: 4px;
}
