@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.navbar-light {
  height: 100vh;
  background: #ffffff;
  /* Light Text */

  border-right: 1px solid #e2e2e2;
  margin-right: 0px !important;

  box-shadow: 4px 0px 37px rgba(0, 0, 0, 0.05);
  align-items: flex-start;
}
.navbar-dark {
  height: 100vh;
  background: #ffffff;
  border-right: 1px solid #e2e2e2;
  /* opacity: 0.15; */

  box-shadow: 4px 0px 37px rgba(0, 0, 0, 0.05);
  align-items: flex-start;
}

.navbar-toggler {
  border-radius: 5px;
  border-color: #000000 !important;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.navbar-text-style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #010018 !important;
}

.nav-link:hover {
  font-weight: 800;
}
.active {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #010018 !important;

  width: 100%;

  background: #e6f2fe;
  border-radius: 3px;
}
.contact-us {
  width: 100%;
  background: #2e2f31;
  border-radius: 5px;
}
.contact-us-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  /* font-size: 11px; */
  line-height: 10px;
  color: #ffffff;
}
.contact-us-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;

  color: #e2e2e2;
}
.contact-us-btn {
  background: rgba(255, 255, 255, 0.27);
  border-radius: 5px;
  border-color: transparent;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.contact-us-btn:hover {
  background: #ffffff;
  color: #2e2f31;
}

/* @media (max-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: absolute;
  }
} */
