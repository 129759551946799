.watches-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #414141;
}

.watches-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 172.7%;
  color: #010018;
}

.delete-btn {
  border: none;
  background: #e86161;
  border-radius: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 172.7%;
  color: #ffffff;
}

.watch-btn {
  border: none;
  background: #4182eb;
  border-radius: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.main-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #010018;
}

.watch-search-bar {
  border: none;
  background: #e2e2e2;
  border-radius: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #414141;
}

.watch-status {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 135%;
  color: #414141;
  background: rgba(45, 156, 219, 0.15);
  border-radius: 12px;
  padding: 4px 12px;
  width: fit-content;
}

.watch-status.disabled {
  background: rgba(235, 87, 87, 0.15);
}

.watch-status2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 135%;
  color: #414141;
  background: rgba(250, 176, 5, 0.15);
  border-radius: 12px;
  padding: 4px 12px;
  width: fit-content;
}

.dropdown-menu {
  min-width: 'fit-content' !important;
  z-index: 1005;
}

.dropdown-item {
  display: flex;
  justify-content: flex-start;
}

.dropdown-item:hover {
  margin-left: 2%;
  width: 96%;
  background: #e2e2e2;
  border-radius: 5px;
}

.watch-dropdown {
  box-shadow: none !important;
  box-sizing: border-box !important;
  background: #ffffff !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 3px !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 172.7% !important;
  color: #010018 !important;
}

.watch-dropdown::after {
  display: none !important;
}

.watch-dropdown2 {
  border: none !important;
  box-shadow: none !important;
  border-radius: 50%;
  width: 100% !important;
  padding: 0% !important;
  background: #ffffff !important;
}

.watch-dropdown2::after {
  display: none !important;
}

.page-active {
  background: #2e2f31;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 7px;
  text-align: center !important;
  border-radius: 50%;
  color: #ffffff;
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 500px) {
  .main-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 12px;
    color: #010018;
  }

  .watch-btn {
    border: none;
    background: #4182eb;
    border-radius: 3px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #ffffff;
  }
}

.main-layout3 {
  max-height: calc(100vh - 200px) !important;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1000;
}

.table th {
  background-color: #f9f9f9;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.customButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.customButton img {
  margin-left: 4px;
  width: 16px;
  height: 16px;
}