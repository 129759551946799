.transfer-watch-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;

  border: none;
  background: #4182eb;
  border-radius: 3px;
}
.transfer-watch-btn2 {
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  /* Secondary Text */

  color: #414141;

  background: #eeeaff;
  border-radius: 3px;
}
.transfer-watch-form-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;

  color: #010018;
}
.transfer-watch-form-text2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #414141;
}
.transfer-watch-form-text3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;

  color: #ffffff;

  background: #8e75ff;
  border-radius: 500px;
}
.transfer-watch-form-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;

  color: #010018;
}
