p {
  margin-bottom: 0% !important;
}
.details-page-main-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;

  color: #010018;
}
.details-page-main-heading-two {
  font-family: "Poppins";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 16px;
  line-height: 150%;

  color: #010018;
}
.transfer-btn {
  border: none;
  background: #4182eb;
  border-radius: 3px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #ffffff;
}
.transfer-btn:hover {
  border: none;
  background: #4182eb;
  border-radius: 3px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #ffffff;
}
.details-page-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 550;
  font-size: 12px;
  line-height: 18px;
  color: #414141;
}
.details-container-heading-light {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  color: #414141;
}
.details-container-heading-dark {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  color: #ffffff;
}

.details-page-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;

  color: #010018;
}
.detail-status {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;

  color: #ffffff;
  background: #78b5f3;
  border-radius: 10px;
}
.detail-status2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;

  color: #ffffff;
  background: #a5b0bb;
  border-radius: 10px;
}
.transaction-table-container {
  width: 100%;
  margin-bottom: 1rem;
  overflow-x: auto;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #dee2e6;
}

.transaction-table th {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #212529;
}

.transaction-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.transaction-table tbody td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px; /* Adjust the value as needed */
}

@media only screen and (max-width: 500px) {
  .transfer-btn {
    border: none;
    background: #4182eb;
    border-radius: 3px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height */

    color: #ffffff;
  }
  .details-page-main-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;

    color: #010018;
  }
  .details-page-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 550;
    font-size: 10px;
    line-height: 11px;

    color: #414141;
  }

  .details-page-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;

    color: #010018;
  }
}
