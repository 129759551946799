.delete-watch-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;

  border: none;
  border-radius: 3px;
  background: #4182eb;
}
.delete-watch-btn2 {
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  /* Secondary Text */

  color: #414141;

  background: #f1f1f1;
  border-radius: 3px;
}
.delete-watch-form-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;

  color: #010018;
}
.delete-watch-form-text2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #414141;
}
.delete-watch-form-text3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;

  color: #ffffff;

  background: #78b5f3;
  border-radius: 500px;
}
.delete-watch-form-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;

  color: #010018;
}
