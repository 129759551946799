.clients-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  color: #414141;
}

.clients-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 172.7%;

  color: #010018;
}

.clients-header-btn {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 3px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 172.7%;

  color: #414141;
}
.client-action-btn {
  border: none !important;
  box-shadow: none !important;
  border-radius: 50%;

  padding: 0% !important;
  background: #ffffff !important;
}
.client-action-btn::after {
  display: none !important;
}
.page-active {
  background: #2e2f31;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 172.7%;
  text-align: center !important;
  border-radius: 50%;
  color: #ffffff;

  width: 20px;
  height: 20px;
}

/* Add these new styles to your existing clients.css file */
.customButton {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
}

.sort-icons {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 4px;
}

.sort-icon {
  transition: opacity 0.2s ease;
  cursor: pointer;
}

.sort-icon.active {
  opacity: 1;
}

.sort-icon:not(.active) {
  opacity: 0.5;
}

.sort-icon:hover {
  opacity: 0.8;
}

/* Prevent text selection when clicking sort buttons */
.customButton span {
  user-select: none;
}