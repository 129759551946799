/* body {
  height: 110vh !important;
} */
.bg {
  background: #f1f1f1;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppin/Poppins-Regular.woff");
}
@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/dm-sans/DMSans-Regular.woff");
}
.failed-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  /* Secondary */

  color: #2e2f31;
}
.swal2-popup {
  font-family: "Poppins" !important;
  font-size: 10px !important;
}
