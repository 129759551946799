.add-watch-form-heading2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #414141;
    opacity: 0.8;
  }


  .UpdateButton {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #ffffff;
    background: #78b5f3;
    border-radius: 10px;
  }

  .error-message {
    background-color: #f44336; /* Red color */
    color: #ffffff; /* White color */
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px; /* Add some space below the error message */
  }
  
  .authenticate-text3 {
    color: #4182eb;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    /* identical to box height */
    /* text-align: center; */

    /* Secondary Text */
    /* color: #414141; */
  }

.otp{
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  font-style: normal;
  color: #414141;
}

  .expire-otp {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    font-style: normal;
    color: #f44336;
  }

  .authenticate-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
       color: #414141;
    /* color: #000000; */
  }



  .container {
    padding: 2px;
  }
  
  .title {
    margin-bottom: 2px;
    font-size: 15px;
    /* color: #6c757d; */
    color:#010018;
    justify-content: left;
    text-align: left;
    align-items: center;
  }
  
  /* .title span {
    font-size: 16px;
    font-weight: bold;
    font-family: Poppins;
  } */
  
  .message {
    font-size: 16px;
    font-weight: bold;
    font-family: Poppins;
    display: flex;
    align-items: left;
    justify-content: left;
    text-align: left;
    padding-top: 1px;
    padding-bottom: 2px;
  }
  
  .resend-code-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .resend-code-container .authenticate-text3 {
    cursor: pointer;
    align-self: flex-end;
  }


  .th-transaction-1 {
    /* padding:0%; */
    margin: 0% !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }


  .transaction-table-container-1 {
    width: 100%;
    margin-bottom: 1rem;
    overflow-x: auto;
  }

  .transaction-table-container-2 {
    width: 100%;
    margin-bottom: 1rem;
    overflow-x: auto;
    border-radius: "";
  }



  .tooltip {
    position: absolute;
    background-color: #000;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    z-index: 100;
    top: 100%;
    left: 0;
    transform: translateY(5px);
    opacity: 0;
    transition: opacity 0.3s ease;
    
  }
  
  .d-flex:hover .tooltip {
    opacity: 1;
  }

  .tooltip-container {
    position: relative;
    cursor: pointer; /* Set cursor to pointer to indicate hoverable */
    z-index: 100;
  }
  
  .tooltip-container:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    background-color: #000;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    z-index: 1000;
    bottom: calc(100% + 5px); /* Adjusted to bottom instead of top */
    left: 50%;
    transform: translateX(-50%);
  }


  /* Active tab button */
.nav-tabs .nav-link.active {
  background-color: #bfcfe8 !important; /* Background color */
  border-color: #bfcfe8 ; /* Border color */
  border-radius: 2px;
}


  /* .watch-detail-modal{
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto !important;
    min-width: auto !important;
   
  } */


  