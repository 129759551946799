.fileDrag {
  box-sizing: border-box;
  background: #ffffff;

  border: 2px dashed #e2e2e2;
  border-radius: 10px;
}
.drag-box-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;

  color: #414141;
}
.drag-box-text2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;

  color: #414141;
}
.update-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;

  border: none;
  background: #4182eb;
  border-radius: 3px;
}
.update-btn:disabled {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;

  border: none;
  background: #c1dff8;
  border-radius: 3px;
}

.update-btn-disable {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  border: none;
  background: #c1dff8;
  border-radius: 3px;
}

.input-box {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 172.7%;
  width: 100%;
  color: #414141;
}
.settings-form-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  color: #010018;
}
.settings-form-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 172.7%;

  color: #414141;
}
.settings-page-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;

  color: #010018;
}

.settings-page-heading1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 172.7%;

  color: #414141;
}
.form-check-input:checked {
  background-color: #4182eb;
  border: none !important;
  box-shadow: none;
  border-color: white !important;
  
  
}

.form-check-input:active {
  outline: none !important;
  box-shadow: none !important;
}

.form-check-input {
  box-shadow: none !important;
  outline: none !important;
  
}
.error-field {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 8px;
  /* identical to box height */

  color: #f05252;
}
