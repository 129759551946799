input:focus {
  outline: none !important;
  border: 1px solid #8e75ff;
}
.fileDrag {
  box-sizing: border-box;
  background: #ffffff;

  border: 2px dashed #e2e2e2;
  border-radius: 10px;
}
.drag-box-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;

  color: #414141;
}
.drag-box-text2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;

  color: #414141;
}
.update-watch-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;

  border: none;
  background: #4182eb;
  border-radius: 3px;
}
.input-box {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 172.7%;

  color: #414141;
}
.update-watch-form-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  color: #2e2f31;
}
.update-watch-form-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;

  color: #2e2f31;
}
