.login-dark {
  background: #2e2f31;
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  height: 100vh;
}
.login-light {
  background: #f1f1f1;
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  height: 100vh;
}

.login-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;

  /* Text Light */

  color: #414141;
}
.login-heading2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  /* Secondary Text */

  color: #414141;
}
.login-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #010018;
}
.login-text2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  /* Secondary Text */

  color: #414141;
}
.sign-in-btn {
  background: #4182eb;
  border: none;
  border-radius: 3px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;
}
.form-control:focus {
  box-shadow: none;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 172.7%;

  color: #414141;
}
.sign-in-inp {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  /* Secondary Text */

  color: #414141;

  opacity: 0.7;
}
.sign-in-inp-error {
  border: 1px solid #f05252;
  background: #fdf2f2;
  border-radius: 6px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 172.7%;
  /* or 21px */

  color: #f05252;
}
.form-control.is-invalid {
  box-shadow: none;
  background-image: none !important;
}
.form-control.is-invalid:focus {
  box-shadow: none;
  background-image: none !important;
}

.show-btn {
  position: absolute;
  border: none;
  background: none;

  right: 7px;
  top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  /* Secondary Text */

  color: #414141;
}
.show-btn-error {
  position: absolute;
  border: none;
  background: none;

  right: 7px;
  top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  /* Secondary Text */

  color: #414141;
}

.show-btn-error1 {
  position: absolute;
  border: none;
  background: none;

  right: 7px;
  top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  /* Secondary Text */

  color: #414141;
}
.show-btn-error2 {
  position: absolute;
  border: none;
  background: none;

  right: 7px;
  top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  /* Secondary Text */

  color: #414141;
}
.invalid-feedback {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 172.7%;
  /* or 21px */

  color: #f05252;
}
