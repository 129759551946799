.watch-dropdown3 {
  background: #e2e2e2 !important;
  border-radius: 50% !important;
  border: none !important;
  box-shadow: none !important;

  padding: 0;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 172.7% !important;

  color: #414141 !important;
}

.watch-dropdown3::after {
  display: none !important;
}
